<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('@/assets/ubisell-logo.png')"
          class="my-3"
          contain
          height="300"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Ubisell licensing backoffice
        </h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import licenze from '@/services/licenzeService.js'
  import { verificaLicenza } from '@/mixins/verificaLicenza.js'
  
  export default {
    name: 'Dashboard',
    mixins: [verificaLicenza],
    data: () => ({
    }),
    created() {
      setTimeout(async () => {
        await licenze.cicleSync()
      }, 1000)
    },
    async mounted() {
      // await this.$store.dispatch('utenti/getModuli')
      await this.verificaModuliLocali()
    }
  }
</script>
